<template>
  <section>
    <div class="panel-content" :style="panelStyle">
      <div v-if="busy" class="loading">
        <div>
          <i class="fa fa-refresh fa-spin"></i>
        </div>
      </div>
      <EquipmentHistoryChartDisplay
        v-if="(requireRTValues && dataset) || !busy"
        :dataset="dataset"
        :widgetOptions="widgetOptions"
        :generateImage="generateImage"
        :disconnection="disconnection"
      />
    </div>
    <div v-if="showCustomToolbar" class="panel-toolbar">
      <slot name="toolbar"></slot>
    </div>
    <EquipmentDisconnectionLogger
      ref="connLogger"
      :panelOptions="panelOptions"
      @ready="disconnectionDataReady = $event"
    />
  </section>
</template>

<script>
import { debounce, isEqual } from "lodash";
import EquipmentHistoryChartDisplay from "@/components/equipment-history-chart-display.vue";
import ChartForm from "@/components/control-sidebar/property-editors/chart-form.vue";
import { defSerie } from "@/components/control-sidebar/property-editors/detail-form-chart.vue";
import EquipmentDisconnectionLogger from "@/components/equipment-disconnection-logger.vue";
export default {
  name: "EquipmentHistoryChartPanel",
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => null
    },
    display: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    EquipmentHistoryChartDisplay,
    EquipmentDisconnectionLogger
  },
  data() {
    return {
      dataset: null,
      refreshing: false,
      showAxles: false,
      hasSamples: false,
      maximumValues: 100,
      minimumValues: 3,
      disconnection: null,
      disconnectionDataReady: null
    };
  },
  computed: {
    contractId() {
      return this.$store.getters["user/contract"]?.id || null;
    },
    generateImage() {
      return this.$route.query?.media == "print";
    },
    panelOptions() {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },
    panelStyle() {
      let parentStyle = this?.$parent?.panelStyle || {};
      let localStyle = {};
      localStyle = {};

      localStyle["min-height"] = parentStyle["min-height"]
        ? parentStyle["min-height"]
        : "auto";
      parentStyle["height"] = "inherit";
      // localStyle["background-color"] = "#ffffff";
      localStyle["overflow"] = "hidden";
      return localStyle;
    },
    dataSetConfig() {
      return this?.panelOptions?.data || [];
    },
    chartOptions() {
      return this?.panelOptions?.chartOptions || null;
    },
    widgetOptions() {
      let options = JSON.parse(JSON.stringify(this?.chartOptions));
      if (options) {
        options.xAxis = options.xAxis || {};
        options.yAxis = options.yAxis || {};
        options.xAxis.show = this.showAxles;
        options.yAxis.show = this.showAxles;
        if (options.yAxis.show) {
          options.yAxis.axisLabel = options.yAxis.axisLabel || {};
        }
        if (options.xAxis.type == "time" && options.xAxis.axisLabel.formatter) {
          options.xAxis.axisLabel.formatter = null; // TODO: make it configurable by user (expression)
        }
        if (this.hasSamples || this.disconnection) {
          if (
            "text" in (options?.title || {}) &&
            !options.title.text &&
            this?.panel?.title
          ) {
            options.title.text = this.panel.title;
          }
        } else {
          options.title = {
            show: true,
            textStyle: {
              color: "#bcbcbc"
            },
            text: this.$t("no_data_found"),
            left: "center",
            top: "center"
          };
        }
        if (!options?.title?.left && options?.title?.textAlign) {
          options.title.left = options?.title?.textAlign || "center";
        }
        // if (this.panelOptions?.realtime) {
        //   // removes last zoom (inside) if realtime
        //   options.dataZoom.pop();
        // }
      }
      return options;
    },
    ids() {
      return (this.dataSetConfig || []).map((i) => parseInt(i.data_id));
    },
    pendingIds() {
      return (this.ids.length && this.$store.getters["history/pending"]) || [];
    },
    hasDataList() {
      return this.$store.getters["dashboard/hasResourceFrom"](
        "data",
        this.equipment?.id
      );
    },
    equipmentDataList() {
      return (this.$store.getters["dashboard/dataList"] || []).filter(
        ({ id }) => this.ids.indexOf(parseInt(id)) >= 0
      );
    },
    dataList() {
      let dataList = this.equipmentDataList;
      let entries = this.$store.getters["history/entries"] || {};
      return this.mode == "editor" || !this.panelOptions.realtime
        ? dataList.map((data) => {
          let item = JSON.parse(JSON.stringify(data));
          item.history = (entries || {})[item.id] || null;
          return item;
        })
        : dataList;
    },
    historyInterval() {
      return this.$store.getters["history/interval"] || null;
    },
    busy() {
      return (
        (this.pendingIds.length > 0 &&
          (!this.panelOptions.realtime || this.mode == "editor")) ||
        this.refreshing
      );
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    requireRTValues() {
      return this.panelOptions.realtime;
    },
    RTvalues() {
      let entry = {};
      this.equipmentDataList.forEach((item) => {
        entry[item.id] = item?.current_value?.value || null;
      });
      return entry;
    },
    showCustomToolbar() {
      return (this?.panel?.toolbar || []).length > 0;
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"] || []
    },
    isDatasetReady() {
      if (this.busy) return false;
      let ids = (this.dataSetConfig || []).map((i) => i.data_id);
      if (!(ids || []).length) return false;
      const status = this.$store.getters["history/ready"];
      return (
        status &&
        this.historyInterval &&
        this.historyInterval.start &&
        this.historyInterval.end &&
        this.ids.some((id) => status[id])
      ) ? true : false
    }
  },
  watch: {
    historyInterval: {
      handler(n) {
        if (this.mode == "editor") return; // it should make use of simulated samples only
        if (this.$el && n && n.start && n.end) {
          if (this.$refs.connLogger) {
            let ids = this.$utils.distinct((this.dataList || []).map(({ device }) => device.connector.id));
            this.$refs.connLogger.fetchDisconnection(ids, this.historyInterval);
          }
          this.fetchHistory();
        }
      },
      deep: true
    },
    chartOptions: {
      handler(n) {
        if (this.$el && n && this.isEditing) {
          this.delayedRefresh();
        }
      },
      deep: true
    },
    dataSetConfig: {
      handler(n, o) {
        if (this.$el && n && this.mode == "editor") {
          let a = (n || []).map(({ data_id }) => data_id).join(",");
          let b = (o || []).map(({ data_id }) => data_id).join(",");
          if ((this.dataset || []).length && a != b) {
            this.parseEquipmentDataSamples();
          } else {
            this.delayedRefresh();
          }
        }
      },
      deep: true
    },
    isEditing: {
      handler(n) {
        if (n) {
          if (this.sidebar.name != "ChartForm") {
            this.$emit("initCustomProperties", {
              panelName: this.panel.name,
              propertyEditor: ChartForm
            });
            // this.$nextTick(() => {
            //   this.setSideBar();
            // });
            // this.delayedRefresh();
          }
        }
      },
      immediate: true
    },
    sidebar: {
      handler(n) {
        if (this.$el && this.isEditing && n && n.name == "ChartForm") {
          // this.onEdit();
        }
      },
      deep: true
    },
    dataList: {
      deep: true,
      handler(n, o) {
        if (isEqual(n, o)) return;
        if (n && this.mode == "viewer" && !this?.dataset?.length) {
          this.setupDatasetForTrend();
        }
      }
    },
    isDatasetReady: {
      handler(n) {
        if (!this.$el || !n) return;
        this.validate();
      },
      immediate: true
    },
    disconnectionDataReady: {
      handler(n) {
        if (!this.$el || !n) return;
        this.validate();
      }
    },
    RTvalues: {
      handler(n) {
        if (n) {
          this.delayedRefresh();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    fetchHistory() {
      let ids = (this.dataSetConfig || []).map((i) => i.data_id);
      if (!(ids || []).length) return; // there are no configured time based data
      this.$store.dispatch("history/fetch", ids);
    },
    parseEquipmentDataSamples() {
      let dataset = [];
      let hasSamples = false;
      (this.ids || []).forEach((id, ix) => {
        let data = this.dataList.find((data) => data.id == id);
        let samples = (data && data?.history?.samples) || [];
        if (!this?.dataSetConfig[ix]) return;
        let cfg = JSON.parse(
          JSON.stringify({
            ...defSerie(this?.dataSetConfig[ix].chartOptions.type),
            ...(this?.dataSetConfig[ix].chartOptions || {})
          })
        );
        if (!("enabled" in cfg.itemStyle)) {
          cfg.itemStyle.enabled = true;
        }
        if (!cfg.itemStyle.enabled) return;
        if (!cfg.name) {
          cfg.name = data.name;
        }
        if (cfg.type == "pie") {
          // let pieCfg =
          //   (dataset || []).find((i) => i.type == "pie") || defPieSerie();
          let pieCfg =
            (dataset || []).find((i) => i.type == "pie") ||
            JSON.parse(JSON.stringify(cfg));
          let value = data?.current_value ? data.current_value.value : 0;
          if (cfg.itemStyle.expression) {
            value = this.$utils.evaluate(
              { data: data },
              cfg.itemStyle.expression
            );
          }
          if (
            value !== "" &&
            (!cfg.validation ||
              this.$utils.isTrue(cfg.validation, {
                $value: parseFloat(value)
              }))
          ) {
            let name = `${cfg.name || data.name}`;
            let nTimes = (pieCfg.data || []).filter(
              (item) => item.name.replace(/^\s+/g, "") == name
            ).length;
            pieCfg.data.push({
              name: `${Array(nTimes + 1).join(" ")}${cfg.name || data.name}`, // must be unique name
              value: parseFloat(value),
              label: {
                show: cfg.label.show,
                color: cfg.label.color,
                position: cfg.label.position,
                formatter: cfg.label.formatter
              },
              labelLine: {
                show: cfg.label.show,
                lineStyle: {
                  color: cfg.label.color
                }
              }
            });
            if (pieCfg.data.length == 1) {
              pieCfg.color = [];
            }
            pieCfg.color.push(cfg.itemStyle.color);
            if (pieCfg.data.length == 1) {
              pieCfg.animation = cfg.animation;
              pieCfg.hoverAnimation = cfg.hoverAnimation;
              dataset.push(pieCfg);
            }
            hasSamples = true;
          }
        } else {
          if (!(data && samples.length > 0)) return;
          if (samples.length) {
            hasSamples = true;
            cfg.data = samples.map((item) => {
              let value = parseFloat(item.value);
              return {
                name: new Date(item.date_time),
                value: [new Date(item.date_time), value],
                data_id: data.id
              };
            });
          }
          if (this.$store.getters.print) {
            cfg.animation = false;
          }
          dataset.push(cfg);
        }
      });
      let pies = (dataset || []).filter((i) => i.type == "pie");
      if (pies.length) {
        pies.forEach((pie) => {
          delete pie.itemStyle;
        });
      }
      // if it has only pie does not show axles
      this.hasSamples = hasSamples;
      this.showAxles = pies.length && pies.length == dataset.length ? false : hasSamples || this.disconnection;
      this.$set(this, "dataset", dataset);
      if (this.mode == "editor") {
        this.$nextTick(() => {
          this.$root.$emit("dashboard:editor", {
            action: "updateWidth"
          });
        });
      }
    },
    setupDatasetForTrend() {
      if (this.dataset?.length) return;
      this.refreshing = true;
      let dataset = [];
      let hasSamples = false;
      this.hasSamples = false;
      this.ids.forEach((id, ix) => {
        let data = this.dataList.find((data) => data.id == id);
        if (!data) return;

        let samples;
        if (this.mode == "editor") {
          samples = data?.history?.samples ?? [];
        } else if (this.chartOptions.trailingValue && data?.current_value) {
          samples = [
            data.current_value,
            { date_time: moment().format(), value: data.current_value.value }
          ];
        } else {
          samples = [];
        }

        let cfg = this.dataSetConfig[ix];
        if (!cfg) return;

        cfg = {
          ...defSerie(cfg.chartOptions.type),
          ...(cfg.chartOptions || {})
        };
        if (!("enabled" in cfg.itemStyle)) {
          cfg.itemStyle.enabled = true;
        }
        if (!cfg.itemStyle.enabled) return;
        if (!cfg.name) {
          cfg.name = data.name;
        }

        // boolean type default treatment
        if (cfg.lineStyle) {
          switch (cfg.lineStyle.waveForm) {
            case "square":
              cfg.step = "end";
              cfg.smooth = false;
              break;
            case "triangle":
              cfg.step = false;
              cfg.smooth = false;
              break;
            case "sin":
              cfg.step = false;
              cfg.smooth = true;
              break;
          }
        }
        if (cfg.validation) {
          samples = samples.filter((sample) => {
            return this.$utils.isTrue(cfg.validation, {
              $value: sample.value
            });
          });
        }
        cfg.data = samples.map((sample) => {
          hasSamples = true;
          return [sample.date_time, parseFloat(sample.value)];
        });
        dataset.push(cfg);
      });
      this.$set(this, "dataset", dataset);
      this.refreshing = false;
      if (this.requireRTValues) {
        this.hasSamples = dataset.length > 0;
        this.setupRefreshTimer();
      } else {
        this.hasSamples = hasSamples;
      }
      this.showAxles = this.hasSamples;
    },
    onEdit() {
      if (this.mode != "editor") return;
      this.trigger({
        action: "chart:activate",
        details: { panelName: this.panel.name }
      });
    },
    trigger(details) {
      this.setSideBar();
      this.$nextTick(() => {
        this.$root.$emit("chart:event", details);
      });
    },
    setSideBar() {
      if (this.sidebar.name != "ChartForm") {
        this.$root.$emit("controlSidebar:setContent", ChartForm);
      }
    },
    refresh() {
      if (this.panelOptions.realtime) {
        if (!this.RTinit) {
          this.RTinit = true;
          this.setupDatasetForTrend();
        }
        else if (!this.isEditing) {
          // updates series with current values
          this.dataset.forEach((d) => {
            const data = this.dataList.find(({ name }) => name == d.name);

            if (data?.current_value?.value) {
              let time = moment().format();
              // prevent ocasional pushes of values with the same time
              if (d.data.at(-1)?.[0] != time) {
                d.data.push([time, parseFloat(data.current_value.value)]);
                d.itemStyle = d.itemStyle || {};
                d.itemStyle.rt = true;
              }
            }

            // check if first value is older than timeWindow
            // and there is at least the <minimum> amount of values
            // OR
            // there is more than the <maximum> amount
            if (
              (d.data.length >= this.minimumValues &&
                moment(d.data.at(-1)[0])
                  .subtract(this.chartOptions.timeWindow, "seconds")
                  .isAfter(d.data.at(0)[0])) ||
              d.data.length > this.maximumValues
            ) {
              d.data.shift();
            }
          });
        }
      }
      this.validate();
    },
    setupRefreshTimer() {
      if (!this.dataset.length || this.mode != "viewer" || this._refreshTimer)
        return;
      this._refreshTimer = setInterval(
        this.refresh.bind(this),
        this.chartOptions.refreshInterval * 1000
      );
    },
    fetchDataList() {
      var query = {
        resource: "data",
        connectorId: this.equipment?.id,
        forceUpdate: false,
        once: true
      };
      return this.$store.dispatch("dashboard/fetchResourcesFrom", query);
    },
    validate() {
      if (!this.$el || !this.isDatasetReady || (!this.isEditing && this.panelOptions.realtime)) return;
      this.disconnection = (this.$refs.connLogger && this.disconnectionDataReady) ? this.$refs.connLogger.disconnection : null;
      this.parseEquipmentDataSamples();
    }
  },
  mounted() {
    if (this.mode != "editor") {
      this.validate();
    } else {
      this.refresh();
    }
  },
  created() {
    if (this.equipment && !this.hasDataList) {
      this.fetchDataList();
    }
  },
  beforeCreate() {
    this.delayedRefresh = debounce(() => {
      this.refresh();
    }, 500);
  },
  beforeDestroy() {
    this.delayedRefresh = null;
  }
};
</script>

<style scoped>
.section {
  position: relative;
}
.loading {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  align-content: center;
  height: inherit;
  font-size: 20pt;
}

.loading > div {
  flex: 1 1 inherit;
  align-self: center;
}

.panel-title {
  padding-left: 10px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.panel-toolbar {
  position: absolute;
  top: 3px;
  left: 0px;
  background-color: transparent;
}

.panel-content {
  z-index: 0;
  min-height: 200px;
  height: 100%;
}
</style>
